import { useState } from "react";

import useScrollTrigger from "../hooks/useScrollTrigger";

import { Element } from "react-scroll";

import ContactBg from "../assets/contact/contact_bg.png";

import { Box, Button, Grid2, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";

import ContactInput from "../components/ContactInput";
import ContactSelect from "../components/ContactSelect";

const slideUp = keyframes`
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Contact = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);

    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        phone: "",
        floors: null,
        address: "",
        type: ""
    });

    return (
        <Element
            id="contact"
            style={{
                scrollMarginTop: 145,
                backgroundColor: "#102429"
            }}
        >
            <Grid2
                container
                paddingBottom={8}
                ref={firstRef}
                sx={{
                    animation: firstVisible ? slideUp+" 0.6s ease-out" : "none",
                    opacity: firstVisible ? 1 : 0,
                    zIndex: 3,
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2
                    size={{ xs: 12 }}
                    display="flex"
                    justifyContent="center"
                >
                    <img
                        src={ContactBg}
                        alt="bg"
                        style={{
                            position: "absolute",
                            zIndex: 2,
                            width: "80vw",
                            height: isMdDown? 0 : 800
                        }}
                    />
                </Grid2>

                <Grid2 size={{ xs: 12 }} zIndex={1}>
                    <Box
                        width="100%"
                        height="20vh"
                        bgcolor="#D8E7EB"
                    />
                </Grid2>

                <Grid2
                    size={{ xs: 8, md: 5 }}
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop={-8}
                    zIndex={3}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        gap={1.5}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#FFF"
                        >
                            <Typography
                                fontFamily="Anton"
                                fontSize={isMdDown ? 30 : 50}
                                fontWeight={400}
                                textTransform="uppercase"
                                color="#102429"
                            >
                                Töltse ki az alábbi
                            </Typography>

                            <Typography
                                fontFamily="Anton"
                                fontSize={isMdDown ? 30 : 50}
                                fontWeight={400}
                                textTransform="uppercase"
                                color="#102429"
                            >
                                űrlapot,
                            </Typography>

                            <Typography
                                fontFamily="Anton"
                                fontSize={isMdDown ? 30 : 50}
                                fontWeight={400}
                                textTransform="uppercase"
                                color="#102429"
                            >
                                hogy elindulhasson!
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            bgcolor="#FFF"
                        >
                            <ContactInput
                                type="text"
                                ph="Teljes név"
                                val={inputs.name}
                                change={(e) => setInputs(prev => ({...prev, name: e.target.value}))}
                            />

                            <ContactInput
                                type="email"
                                ph="Email"
                                val={inputs.email}
                                change={(e) => setInputs(prev => ({...prev, email: e.target.value}))}
                            />

                            <ContactInput
                                type="text"
                                ph="Telefonszám"
                                val={inputs.phone}
                                change={(e) => setInputs(prev => ({...prev, phone: e.target.value}))}
                            />

                            <ContactInput
                                type="number"
                                ph="Szintek száma"
                                val={inputs.floors}
                                change={(e) => setInputs(prev => ({...prev, floors: e.target.value}))}
                            />

                            <ContactInput
                                type="text"
                                ph="Ingatlan címe"
                                val={inputs.address}
                                change={(e) => setInputs(prev => ({...prev, address: e.target.value}))}
                            />

                            <ContactSelect
                                val={inputs.type}
                                change={(e) => setInputs(prev => ({...prev, type: e.target.value}))}
                            />

                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                sx={{
                                    fontFamily: "Rubik",
                                    fontSize: 14,
                                    fontWeight: 700,
                                    letterSpacing: 2,
                                    borderRadius: 0,
                                    height: 70,
                                    bgcolor: "#1386EB",
                                    "&:hover": {
                                        bgcolor: "#299BFF"
                                    }
                                }}
                            >
                                Ajánlatkérés
                            </Button>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Element>
    );
};

export default Contact;