import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";

const FooterButton = ({ icon, text }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Button
            size="small"
            sx={{
                textTransform: "none",
                "&:hover": {
                    bgcolor: "inherit",
                    "& .onhover": {
                        opacity: 1
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
            >
                <img
                    src={icon}
                    alt="icon"
                />

                <Typography
                    className="onhover"
                    fontFamily="Rubik"
                    fontSize={isMdDown ? "10px" : "14px"}
                    color="#FFF"
                    sx={{ opacity: 0.5 }}
                >
                    {text}
                </Typography>
            </Box>
        </Button>
    );
};

export default FooterButton;