import { Grid2 } from "@mui/material";



const Reference = () => {

    return (
        
        

        <Grid2
            container
            bgcolor="#FFF"
        >

        </Grid2>
    );
};

export default Reference;