const ContactSelect = ({ val, change }) => {
    return (
        <select
            value={val}
            onChange={change}
            style={{
                fontFamily: "Rubik",
                fontSize: "15px",
                padding: 20,
                borderRadius: 0,
                boxShadow: "none",
                border: "1px solid #FFF",
                outline: "none",
                boxSizing: "border-box"
            }}
            onFocus={(e) => {
                e.target.style.outline = "none"
                e.target.style.border = "1px solid #1386EB"
            }}
            onBlur={(e) => e.target.style.border = "1px solid #FFF"}
        >
            <option value="">Building Type...</option>
            <option value="Commercial">Commercial</option>
            <option value="Industrial">Industrial</option>
            <option value="Residential">Residential</option>
            <option value="Other">Other</option>
        </select>
    );
};

export default ContactSelect;