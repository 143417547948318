import useScrollTrigger from "../hooks/useScrollTrigger";

import { Element } from 'react-scroll';

import Logo from "../assets/logo_black.svg";

import { Box, Button, Divider, Grid2, keyframes, Typography, useMediaQuery, useTheme } from '@mui/material';

const slideInFromRight = keyframes`
    from {
        transform: translateX(30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideInFromLeft = keyframes`
    from {
        transform: translateX(-30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const About = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);
    const [secondVisible, secondRef] = useScrollTrigger(0.1);

    return (
        <Element
            id="about"
            style={{
                scrollMarginTop: 145,
                backgroundColor: "#0F2025"
            }}
        >
            <Grid2
                container
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2 size={{ xs: 12 }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={3}
                        marginRight={9}
                        marginLeft={9}
                        borderBottom="0.5px solid #1D363C"
                    >
                        <Divider
                            sx={{
                                width: "70px",
                                bgcolor: "#2086E5"
                            }}
                        />

                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: 200,
                                height: 162
                            }}
                        />
                    </Box>
                </Grid2>

                <Grid2
                    size={{ xs: 12, md: 6 }}
                    borderBottom="0.5px solid #1D363C"
                    borderRight="0.5px solid #1D363C"
                    ref={firstRef}
                    sx={{
                        animation: firstVisible ? slideInFromLeft+" 0.6s ease-in-out" : "none",
                        opacity: firstVisible ? 1 : 0
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1.5}
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={2.5}
                        paddingLeft={isMdDown ? 4 : 8}
                        paddingRight={isMdDown ? 4 : 8}
                        width="100%"
                        boxSizing="border-box"
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 20 : 26}
                            fontWeight={400}
                            color="#FFF"
                            textTransform="uppercase"
                        >
                            WASH GLOBAL vízió
                        </Typography>

                        <Divider
                            sx={{
                                width: "30vw",
                                bgcolor: "#1D363C"
                            }}
                        />

                        <Typography
                            fontFamily="Rubik"
                            fontSize={isMdDown ? 14 : 18}
                            color="#B4CFD6"
                            textAlign="center"
                            paddingTop={2}
                            paddingBottom={2}
                            height={isMdDown ? 330 : 430}
                        >
                            A DRONE WASH GLOBAL elkötelezett amellett, hogy forradalmasítsa a tisztítási szolgáltatások jövőjét a legmodernebb dróntechnológia révén. A DRONE WASH GLOBAL-nál elkötelezetten törekszünk arra, hogy új mércét állítsunk a tisztításban, páratlan pontosságot, gyorsaságot és költséghatékonyságot nyújtva a hagyományos módszerekkel szemben. Újradefiniáljuk a tisztítás fogalmát, kiváló minőségű, automatizált megoldásokat kínálva, amelyek nagy mértékben javítják ügyfeleink tervezhetőségét, kiadásait. 
                        </Typography>

                        <Divider
                            sx={{
                                width: "30vw",
                                bgcolor: "#1D363C"
                            }}
                        />

                        <Button
                            href="#contact"
                            sx={{
                                color: "#FFF",
                                fontFamily: "Rubik",
                                fontSize: 14,
                                fontWeight: 700,
                                letterSpacing: 3,
                                marginBottom: 1.5,
                                "&:hover": {
                                    bgcolor: "inherit",
                                    color: "#6A7A7D"
                                }
                            }}
                        >
                            Ajánlatkérés
                        </Button>
                    </Box>
                </Grid2>

                <Grid2
                    size={{ xs: 12, md: 6 }}
                    borderBottom="0.5px solid #1D363C"
                    ref={secondRef}
                    sx={{
                        animation: secondVisible ? slideInFromRight+" 0.6s ease-in-out" : "none",
                        opacity: secondVisible ? 1 : 0
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1.5}
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={2.5}
                        paddingLeft={isMdDown ? 3 : 8}
                        paddingRight={isMdDown ? 3 : 8}
                        width="100%"
                        boxSizing="border-box"
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 20 : 26}
                            fontWeight={400}
                            color="#FFF"
                            textTransform="uppercase"
                        >
                            WASH GLOBAL küldetés
                        </Typography>

                        <Divider
                            sx={{
                                width: "30vw",
                                bgcolor: "#1D363C"
                            }}
                        />

                        <Typography
                            fontFamily="Rubik"
                            fontSize={isMdDown ? 14 : 18}
                            color="#B4CFD6"
                            textAlign="center"
                            paddingTop={2}
                            paddingBottom={2}
                            height={isMdDown ? 330 : 430}
                        >
                            "Küldetésünk, hogy páratlan hatékonyságot, innovációt és fenntarthatóságot nyújtsunk a tisztítás területén, miközben maximalizáljuk a biztonságot és minimalizáljuk a környezeti hatásokat. 
                            Küldetésünk, hogy az emberi munkaerő számára jelentős kockázattal bíró tisztítási folyamatokat kíváltsuk, és ezzel elkerüljük Ügyfeleink számára az ebből adódó biztonsági kockázatokat. "
                        </Typography>

                        <Divider
                            sx={{
                                width: "30vw",
                                bgcolor: "#1D363C"
                            }}
                        />

                        <Button
                            href="#contact"
                            sx={{
                                color: "#FFF",
                                fontFamily: "Rubik",
                                fontSize: 14,
                                fontWeight: 700,
                                letterSpacing: 3,
                                marginBottom: 1.5,
                                "&:hover": {
                                    bgcolor: "inherit",
                                    color: "#6A7A7D"
                                }
                            }}
                        >
                            Kapcsolat felvétel
                        </Button>
                    </Box>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={2}
                        paddingBottom={8}
                    >
                        <Divider
                            sx={{
                                width: "70px",
                                bgcolor: "#2086E5"
                            }}
                        />
                    </Box>
                </Grid2>
                
            </Grid2>
        </Element>
    );
};

export default About;