import useScrollTrigger from "../hooks/useScrollTrigger";

import Logo from "../assets/logo_white.svg";
import TitleBg from "../assets/services/title_bg.svg";

import Step1 from "../assets/steps/step_1.svg";
import Step2 from "../assets/steps/step_2.svg";
import Step3 from "../assets/steps/step_3.svg";
import Step4 from "../assets/steps/step_4.svg";
import Step5 from "../assets/steps/step_5.svg";
import Step6 from "../assets/steps/step_6.svg";
import Step8 from "../assets/steps/step_8.svg";
import Step9 from "../assets/steps/step_9.svg";
import Step10 from "../assets/steps/step_10.svg";

import { Box, Grid2, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";

import Step from "./content/Step";

const slideUp = keyframes`
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Steps = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);

    return (
        <Grid2
            container
            bgcolor="#FFF"
        >
            <Grid2
                container
                size={{ xs: 12 }}
                spacing={3}
                paddingLeft={isMdDown ? 3 : 7}
                paddingRight={isMdDown ? 3 : 7}
                paddingBottom={10}
                ref={firstRef}
                sx={{
                    animation: firstVisible ? slideUp+" 0.6s ease-out" : "none",
                    opacity: firstVisible ? 1 : 0,
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2 size={{ xs: 12 }} marginBottom={3}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                        width={isMdDown ? "100%" : "50%"}
                        borderLeft="1px solid #D8E7EB"
                        borderRight="1px solid #D8E7EB"
                        margin="0 auto"
                        paddingLeft={isMdDown ? 0 : 10}
                        paddingRight={isMdDown ? 0 : 10}
                    >
                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: "200px",
                                height: "164px"
                            }}
                        />

                        <Box
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                src={TitleBg}
                                alt="title_bg"
                                style={{
                                    position: "absolute",
                                    transform: "rotate(180deg)",
                                    marginTop: isMdDown ? 14 : 30,
                                    width: isMdDown ? 300 : 550
                                }}
                            />

                            <Typography
                                fontFamily="Anton"
                                fontSize={isMdDown ? 20 : 40}
                                fontWeight={isMdDown ? 400 : 700}
                                textTransform="uppercase"
                                color="#102429"
                                letterSpacing={1.4}
                                zIndex={10}
                            >
                                Folyamatunk lépésről lépésre
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>

                <Step
                    title="1. Előzetes helyszíni bejárás:"
                    desc="Minden tisztítási feladat előtt csapatunk alapos előzetes ellenőrzést végez, hogy felmérje a terület állapotát, azonosítsa az esetleges kihívásokat, és megtervezze a leghatékonyabb tisztítási stratégiát."
                    icon={Step1}
                />

                <Step
                    title="2. Drónok előkészítése:"
                    desc="Szakértő technikusaink precízen konfigurálják és előkészítik a drónt, biztosítva, hogy az a szükséges tisztítóeszközökkel és technológiával legyen felszerelve, melyek megfelelnek a konkrét tisztítási igényeknek."
                    icon={Step2}
                />

                <Step
                    title="3. Biztonsági számítás:"
                    desc="A biztonságot előtérbe helyezve szigorú biztonsági intézkedéseket vezetünk be, hogy megvédjük csapatunkat és a környező területet. Ez magában foglalja az iparági előírások betartását és a fejlett biztonsági felszerelések használatát."
                    icon={Step3}
                />

                <Step
                    title="4. Repülési terv:"
                    desc="Szakértőink gondosan megtervezik a drón repülési útvonalát, figyelembe véve a tisztítandó építmény méreteit és jellemzőit. Ez a gondos tervezés biztosítja a pontos navigációt és az optimális lefedettséget."
                    icon={Step4}
                />

                <Step
                    title="5. Tisztítási folyamat:"
                    desc="Utilizing state-of-the-art cleaning technology, our drones execute a systematic and efficient cleaning process, addressing dirt, dust, and other contaminants. This automated approach allows for a consistent and high-quality cleaning outcome."
                    icon={Step5}
                />

                <Step
                    title="6. Preciziós irányítás:"
                    desc="Szakértő operátoraink a tisztítási művelet során precíz irányítást gyakorolnak, pontosan kezelve a drón mozgását, hogy biztosítsák a teljes lefedettséget és minimalizálják a sérülés kockázatát."
                    icon={Step6}
                />

                <Step
                    title="7. Problémás területek újravizsgálata:"
                    desc="If the initial cleaning pass does not achieve the desired level of cleanliness, the drone can revisit specific windows for a second pass. This ensures that even stubborn stains or grime are effectively removed."
                    icon={Step3}
                />

                <Step
                    title="8. Végső ellenőrzés:"
                    desc="Folyamatunk magában foglalja az előzetes ellenőrzés során azonosított problémás területek újbóli átvizsgálását. A drón célzottan kezeli ezeket a területeket, biztosítva a teljes körű tisztítási megoldást."
                    icon={Step8}
                />

                <Step
                    title="9. Berendezések karbantartása:"
                    desc="A rendszeres karbantartás elengedhetetlen a drónok optimális teljesítményéhez. Csapatunk rutinszerű ellenőrzéseket és karbantartásokat végez, hogy biztosítsa berendezéseink megbízhatóságát és hatékonyságát a jövőbeni tisztítási feladatokhoz."
                    icon={Step9}
                />

                <Step
                    title="10. Adat gyűjtés:"
                    desc="Folyamatunk alapos adatgyűjtéssel kezdődik, ahol összegyűjtjük az építményre vonatkozó információkat, annak méreteit és a speciális tisztítási igényeket. Ez az adatvezérelt megközelítés lehetővé teszi, hogy tisztítási stratégiánkat az optimális eredmények elérésére szabjuk."
                    icon={Step10}
                />

                <Step
                    title="11. Biztonságos visszatérés:"
                    desc="A biztonságot minden szakaszban előtérbe helyezve drónjainkat fejlett visszaszerző rendszerekkel szereltük fel, hogy a tisztítási feladat befejezése után biztonságos és irányított visszatérés biztosított legyen. A biztonság minden műveletünk során elsődleges szempont."
                    icon={Step3}
                />

                <Step
                    title="12. Következő tisztítás foglalása:"
                    desc="A kényelem és a proaktív karbantartás érdekében lehetőséget biztosítunk a következő tisztítási időpont előzetes ütemezésére. Ez biztosítja a folyamatosan karbantartott és tiszta környezetet, ügyfeleink pedig élvezhetik az előre tervezett és gondtalan tisztítási szolgáltatás előnyeit."
                    icon={Step1}
                />
            </Grid2>
        </Grid2>
    );
};

export default Steps;