import useScrollTrigger from "../hooks/useScrollTrigger";

import { Element } from "react-scroll";

import TitleBg from "../assets/services/title_bg.svg";
import Cleaner from "../assets/services/cleaner.svg";

import { Box, Button, Divider, Grid2, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";

const slideUp = keyframes`
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Services = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);
    const [btnVisible, btnRef] = useScrollTrigger(0.1);

    return (
        <Element
            id="services"
            style={{
                scrollMarginTop: 145,
                backgroundColor: "#FFF"
            }}
        >
            <Grid2
                container
                ref={firstRef}
                sx={{
                    animation: firstVisible ? slideUp+" 0.6s ease-out" : "none",
                    opacity: firstVisible ? 1 : 0,
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2 size={{ xs: 12 }}>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={3}
                        paddingBottom={8}
                    >
                        <Divider
                            sx={{
                                width: "70px",
                                bgcolor: "#2086E5"
                            }}
                        />
                    </Box>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            src={TitleBg}
                            alt="title_bg"
                            style={{
                                position: "absolute",
                                transform: "rotate(180deg)",
                                marginBottom: 20,
                                width: isMdDown ? "350px" : "auto"
                            }}
                        />

                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 25 : 45}
                            fontWeight={isMdDown ? 400 : 700}
                            textTransform="uppercase"
                            color="#102429"
                            letterSpacing={1.4}
                            borderLeft={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                            borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                            paddingLeft={isMdDown ? 3 : 8}
                            paddingRight={isMdDown ? 3 : 8}
                            paddingBottom={6}
                            zIndex={10}
                        >
                            DRONE WASH GLOBAL szolgáltatások
                        </Typography>
                    </Box>
                </Grid2>

                <Grid2
                    container
                    size={{ xs: 12 }}
                    sx={{
                        paddingLeft: isMdDown ? 3 : 8,
                        paddingRight: isMdDown ? 3 : 8
                    }}
                >
                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                        borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >


                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Magasnyomású mosás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            60 méter magasságig speciális felületmosó drónjainkkal mossuk tisztára üvegfelületeit vagy akár homlokzatát.
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop="0.5px solid #B5CFD6"
                        borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >
                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Ipari tisztítás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            Szakértő csapatunk fejlett technikákat és felszerelést alkalmaz a szennyeződések hatékony eltávolítása érdekében, ezáltal elősegítve a higiénikus és előírásoknak megfelelő munkakörnyezetet.
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop="0.5px solid #B5CFD6"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >


                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Lakossági tisztítás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            Frissességet hozunk minden sarokba, biztosítva a makulátlan és hívogató otthoni környezetet. Tapasztalja meg a tiszta otthon kényelmét professzionális és megbízható lakossági tisztítási szolgáltatásunkkal.
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop="0.5px solid #B5CFD6"
                        borderBottom="0.5px solid #B5CFD6"
                        borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >


                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Közepesen emelkedő tisztítás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            Szakértő csapatunk hatékony tisztítási módszereket alkalmaz, hogy biztosítsa a kifogástalan megjelenést és az optimális karbantartást a közepes magasságú épületek számára.
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop="0.5px solid #B5CFD6"
                        borderBottom="0.5px solid #B5CFD6"
                        borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >


                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Kereskedelmi tisztítás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            Szakértő csapatunk hatékony technikákat alkalmaz, hogy makulátlan és hívogató légkört teremtsen, ezzel javítva az ügyfelek általános élményét.
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 4 }}
                        padding={5}
                        borderTop="0.5px solid #B5CFD6"
                        borderBottom="0.5px solid #B5CFD6"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="8em"
                            height="8em"
                            bgcolor="#FFF"
                            border="2px solid #ffffff40"
                            borderRadius="50%"
                            padding="2em"
                            boxSizing="border-box"
                            marginRight={3}
                            sx={{
                                backgroundImage: "radial-gradient(circle, #ffffff80 25%, #1386eb40)",
                                backdropFilter: "blur(5px)",
                                float: "left"
                            }}
                        >
                            <img
                                src={Cleaner}
                                alt="cleaner"
                                style={{ width: "52px", height: "56px" }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >


                            <Typography
                                fontFamily="Anton"
                                fontSize={25}
                                fontWeight={400}
                                color="#102429"
                                textTransform="uppercase"
                            >
                                Garázs tisztítás
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily="Rubik"
                            fontSize={17}
                            color="#2E4A52"
                            marginTop={1}
                        >
                            Elhivatott csapatunk gondoskodik a szennyeződések, törmelékek és foltok eltávolításáról, így tiszta és hívogató teret biztosít mind a járműtulajdonosok, mind a gyalogosok számára.
                        </Typography>
                    </Grid2>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Box
                        width="fit-content"
                        margin="0 auto"
                        borderLeft="0.5px solid #B5CFD6"
                        borderRight="0.5px solid #B5CFD6"
                        paddingTop={4}
                        paddingLeft={4}
                        paddingRight={4}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            href="#contact"
                            ref={btnRef}
                            sx={{
                                fontFamily: "Rubik",
                                fontSize: isMdDown ? 10 : 12,
                                fontWeight: 700,
                                letterSpacing: 2,
                                borderRadius: 0,
                                width: "50vw",
                                height: 70,
                                bgcolor: "#1386EB",
                                animation: btnVisible ? slideUp+" 0.6s ease-out" : "none",
                                opacity: btnVisible ? 1 : 0,
                                "&:hover": {
                                    bgcolor: "#299BFF"
                                }
                            }}
                        >
                            Kapcsolat felvétel
                        </Button>
                    </Box>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={3}
                        paddingBottom={8}
                    >
                        <Divider
                            sx={{
                                width: "70px",
                                bgcolor: "#2086E5"
                            }}
                        />
                    </Box>
                </Grid2>

            </Grid2>
        </Element>
    );
};

export default Services;