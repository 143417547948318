import { useState } from "react";

import { alpha, AppBar, Box, Button, Drawer, List, ListItem, ListItemButton, styled, useMediaQuery, useTheme } from "@mui/material";

import Logo from "../assets/logo_white.svg";
import NavIcon from "../assets/navbar/hamburger.svg";

const AnimatedAppBar = styled(AppBar)(({ theme }) => ({
    animation: 'slideDown 0.6s ease-out',
    '@keyframes slideDown': {
        from: {
            transform: 'translateY(-100%)'
        },
        to: {
            transform: 'translateY(0)'
        }
    }
}));

const NavBar = ({ scrolling }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [open, setOpen] = useState(false);

    return (
        <AnimatedAppBar
            position="sticky"
            sx={{
                top: 0,
                left: 0,
                backgroundColor: scrolling ? alpha('#FFF', 0.7) : alpha('#D8E7EB', 0.7),
                backdropFilter: 'blur(6px)',
                boxShadow: 'none',
                transitionTimingFunction: "ease-in",
                transition: "0.6s",
                zIndex: 100
            }}
        >
            {isMdDown ?
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                    height="100%"
                    sx={{
                        marginLeft: isMdDown ? 2 : 8.5,
                        marginRight: isMdDown ? 2 : 8.5,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderBottom: scrolling ? 0 : "1px solid #B5CFD6"
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => setOpen(prev => !prev)}
                        sx={{
                            fontFamily: "Rubik",
                            fontSize: 10,
                            letterSpacing: 2,
                            fontWeight: 700,
                            borderRadius: 0,
                            bgcolor: "#1386EB",
                            height: 50,
                            "&:hover": {
                                bgcolor: "#299BFF"
                            }
                        }}
                    >
                        <img
                            src={NavIcon}
                            alt="nav"
                            style={{ width: 30, height: 30 }}
                        />
                    </Button>

                    <Drawer
                        anchor="right"
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        <List>
                            <ListItem sx={{ marginBottom: 4 }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    width="100%"
                                >
                                    <img
                                        src={Logo}
                                        alt="logo"
                                        style={{
                                            width: 138,
                                            height: 113
                                        }}
                                    />
                                </Box>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton
                                    href="#about"
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        color: "#102429",
                                        fontFamily: "Rubik",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        letterSpacing: 1.7,
                                        padding: 2,
                                        textTransform: "uppercase"
                                    }}
                                >
                                    Rólunk
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton
                                    href="#services"
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        color: "#102429",
                                        fontFamily: "Rubik",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        letterSpacing: 1.7,
                                        padding: 2,
                                        textTransform: "uppercase"
                                    }}
                                >
                                    Szolgáltatások
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton
                                    href="#comments"
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        color: "#102429",
                                        fontFamily: "Rubik",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        letterSpacing: 1.7,
                                        padding: 2,
                                        textTransform: "uppercase"
                                    }}
                                >
                                    Vélemények
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton
                                    href="#contact"
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        color: "#102429",
                                        fontFamily: "Rubik",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        letterSpacing: 1.7,
                                        padding: 2,
                                        textTransform: "uppercase"
                                    }}
                                >
                                    Kapcsolat
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton
                                    href="#contact"
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        color: "#FFF",
                                        bgcolor: "#1386EB",
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        fontFamily: "Rubik",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        letterSpacing: 1.7,
                                        textTransform: "uppercase"
                                    }}
                                >
                                    Ajánlatkérés
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Drawer>

                    <a href={window.location.origin}>
                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: 138,
                                height: 113
                            }}
                        />
                    </a>
                </Box>
            :
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    height="100%"
                    sx={{
                        marginLeft: isMdDown ? 2 : 8.5,
                        marginRight: isMdDown ? 2 : 8.5,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderBottom: scrolling ? 0 : "1px solid #B5CFD6",
                        maxWidth: "1200px",
                        margin: "0 auto",
                        width: "100%"
                    }}
                >
                    <a
                        href={window.location.origin}
                        style={{ marginRight: "auto" }}
                    >
                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: 200,
                                height: "auto"
                            }}
                        />
                    </a>

                    <Button
                        href="#about"
                        sx={{
                            color: "#102429",
                            fontFamily: "Rubik",
                            fontSize: 11,
                            fontWeight: 700,
                            letterSpacing: 1.7,
                            "&:hover": {
                                bgcolor: "inherit",
                                color: "#6A7A7D"
                            }
                        }}
                    >
                        Rólunk
                    </Button>

                    <Button
                        href="#services"
                        sx={{
                            color: "#102429",
                            fontFamily: "Rubik",
                            fontSize: 11,
                            fontWeight: 700,
                            letterSpacing: 1.7,
                            "&:hover": {
                                bgcolor: "inherit",
                                color: "#6A7A7D"
                            }
                        }}
                    >
                        Szolgáltatások
                    </Button>

                    <Button
                        href="#comments"
                        sx={{
                            color: "#102429",
                            fontFamily: "Rubik",
                            fontSize: 11,
                            fontWeight: 700,
                            letterSpacing: 1.7,
                            "&:hover": {
                                bgcolor: "inherit",
                                color: "#6A7A7D"
                            }
                        }}
                    >
                        Vélemények
                    </Button>


                    <Button
                        href="#contact"
                        sx={{
                            color: "#102429",
                            fontFamily: "Rubik",
                            fontSize: 11,
                            fontWeight: 700,
                            letterSpacing: 1.7,
                            "&:hover": {
                                bgcolor: "inherit",
                                color: "#6A7A7D"
                            }
                        }}
                    >
                        Kapcsolat
                    </Button>

                    <Button
                        href="#contact"
                        variant="contained"
                        size="large"
                        sx={{
                            fontFamily: "Rubik",
                            fontSize: 10,
                            letterSpacing: 2,
                            fontWeight: 700,
                            borderRadius: 0,
                            bgcolor: "#1386EB",
                            height: 50,
                            marginTop: 4,
                            marginLeft: 2,
                            alignSelf: "flex-start",
                            "&:hover": {
                                bgcolor: "#299BFF"
                            }
                        }}
                    >
                        Ajánlatkérés
                    </Button>
                </Box>
            }
        </AnimatedAppBar>
    );
};

export default NavBar;