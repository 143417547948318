import { Box } from "@mui/material";

const StepIcon = ({ icon }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#1386EB"
            borderRadius="12px"
            width="56px"
            height="56px"
        >
            <img
                src={icon}
                alt="icon"
            />
        </Box>
    );
};

export default StepIcon;