import React, { useState } from 'react';
import cleanImage from '../assets/afterbefor/CleanBuilding.png';
import dirtyImage from '../assets/afterbefor/DirtyBuilding.png';

import { Box, Divider, Grid2 } from '@mui/material';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';

import { Element } from 'react-scroll';

const AfterBefore = () => {
    const [sliderPosition, setSliderPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);

    const handleMove = (clientX, container) => {
        const containerRect = container.getBoundingClientRect();
        const newSliderPosition = ((clientX - containerRect.left) / containerRect.width) * 100;
        setSliderPosition(Math.min(Math.max(newSliderPosition, 0), 100));
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        handleMove(e.clientX, e.currentTarget);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();  // Megakadályozza az oldal görgetését
        handleMove(e.touches[0].clientX, e.currentTarget);
    };

    const handleStart = () => {
        setIsDragging(true);
    };

    const handleEnd = () => {
        setIsDragging(false);
    };

    return (
        <Element 
            id="afterbefor"
            style={{
                scrollMarginTop: 145,
                paddingTop: 24,
                paddingBottom: 16,
                touchAction: isDragging ? 'none' : 'auto', // Kikapcsolja a touch-action csak amikor a csúszka aktív
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: '1400px',
                        height: '100%',
                        overflow: 'hidden',
                        cursor: 'ew-resize',
                    }}
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleTouchMove}
                    onMouseUp={handleEnd}
                    onTouchEnd={handleEnd}
                    onMouseLeave={handleEnd}
                >
                    {/* Háttérben a koszos kép */}
                    <Box
                        component="img"
                        src={dirtyImage}
                        alt="Koszos épület"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            userSelect: 'none',
                            pointerEvents: 'none',
                        }}
                    />

                    {/* Előtérben a tiszta kép, amely csak a csúszka pozíciójáig látható */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            clipPath: `inset(0 0 0 ${sliderPosition}%)`,
                            pointerEvents: 'none',
                        }}
                    >
                        <Box
                            component="img"
                            src={cleanImage}
                            alt="Tiszta épület"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        />
                    </Box>

                    {/* Függőleges csúszka a pozíció alapján, ikon a közepén */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            width: '6px',
                            backgroundColor: '#2e66de',
                            cursor: 'ew-resize',
                            transform: 'translateX(-50%)',
                            left: `${sliderPosition}%`,
                            touchAction: 'pan-y', // Csak a vertikális érintéses mozgást engedélyezi
                        }}
                        onMouseDown={handleStart}
                        onTouchStart={handleStart}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '40px',
                                height: '40px',
                                backgroundColor: '#2e66de',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                userSelect: 'none',
                            }}
                        >
                            <ControlCameraIcon sx={{ color: '#ffffff', fontSize: 28 }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid2 size={{ xs: 12 }}>
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    paddingTop={2}
                    paddingBottom={8}
                >
                    <Divider
                        sx={{
                            width: "70px",
                            bgcolor: "#2086E5"
                        }}
                    />
                </Box>
            </Grid2>
        </Element>
    );
};

export default AfterBefore;