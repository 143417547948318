import { Box, Grid2, Typography } from "@mui/material";

import StepIcon from "../../components/StepIcon";

const Step = ({ title, desc, icon }) => {
    return (
        <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                width="100%"
            >
                <StepIcon
                    icon={icon}
                />

                <Typography
                    fontFamily="Montserrat"
                    fontSize="24px"
                    fontWeight={700}
                    color="#0D0D0D"
                >
                    {title}
                </Typography>

                <Typography
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight={500}
                    color="#0D0D0D"
                >
                    {desc}
                </Typography>
            </Box>
        </Grid2>
    );
};

export default Step;