import { useState } from "react";

const Phone = () => {
    const [hovered, setHovered] = useState(false);

    return (
        <svg
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill={hovered ? "#6A7A7D" : "#000"}
            style={{ transition: "fill 0.2s ease", cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.1529 0.769421C4.91228 0.188196 4.27794 -0.121165 3.67171 0.0444527L0.921834 0.79442C0.378108 0.944413 0 1.43814 0 2.00062C0 9.73153 6.26847 16 13.9994 16C14.5619 16 15.0556 15.6219 15.2056 15.0782L15.9555 12.3283C16.1212 11.7221 15.8118 11.0877 15.2306 10.8471L12.2307 9.59716C11.7214 9.38467 11.1308 9.53153 10.7839 9.95964L9.52146 11.5002C7.32155 10.4596 5.54038 8.67845 4.4998 6.47854L6.04036 5.21922C6.46846 4.86924 6.61533 4.28177 6.40284 3.77241L5.1529 0.772546V0.769421Z" />
        </svg>
    );
};

export default Phone;