import React from "react";
import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const Comment = ({ name, content, starfilled, staroutline }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
            padding={2}
            boxSizing="border-box"
            width="100%"
        >
            <Typography
                fontFamily="Anton"
                fontSize={26}
                fontWeight={400}
                textTransform="uppercase"
                color="#102429"
            >
                {name}
            </Typography>

            <Divider
                sx={{
                    bgcolor: "#B5CFD6",
                    width: "80%"
                }}
            />

            <Typography
                fontFamily="Rubik"
                fontSize={isMdDown ? 18 : 22}
                fontStyle="italic"
                color="#2E4A52"
                textAlign="center"
                padding={isMdDown ? 2 : 6}
                height={235}
                zIndex={1}
                width={'80%'}
            >
                {content}
            </Typography>

            <Divider
                sx={{
                    bgcolor: "#B5CFD6",
                    width: "80%"
                }}
            />

            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                paddingTop={2}
                paddingBottom={2}
            >
                {[...Array(Number(starfilled))].map((_, index) => (
                    <StarIcon
                        key={`filled-${index}`}
                        sx={{
                            color: "#1386EB",
                            fontSize: 20, // Méret beállítása
                        }}
                    />
                ))}
                {[...Array(Number(staroutline))].map((_, index) => (
                    <StarOutlineIcon
                        key={`outline-${index}`}
                        sx={{
                            color: "#1386EB",
                            fontSize: 20, // Méret beállítása
                        }}
                    />
                ))}
            </Box>
            
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="#B4CFD6"
                fontFamily="Rubik"
                fontSize="16em"
                fontWeight={900}
                position="absolute"
                zIndex={0}
                paddingTop=".45em"
            >
                ''
            </Box>
        </Box>
    );
};

export default Comment;