import useScrollTrigger from "../hooks/useScrollTrigger";

import { Box, Grid2, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";

import DronePNG from "../assets/home/drone.png";

import Phone from "../assets/home/Phone";
import Mail from "../assets/home/Mail";
import Location from "../assets/home/Location";
import FacebookIcon from '@mui/icons-material/Facebook';
import Instagram from "../assets/home/Instagram";
import Linkedin from "../assets/home/Linkedin";

const slideDown = keyframes`
    from {
        transform: translateY(-400%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const boxSlideDown = keyframes`
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const boxSlideUp = keyframes`
    from {
        transform: translateY(50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const growAnimation = keyframes`
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`;

const growAnimationForText = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const slideInFromRight = keyframes`
    from {
        transform: translateX(120%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideInFromLeft = keyframes`
    from {
        transform: translateX(-120%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const Home = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);
    const [secondVisible, secondRef] = useScrollTrigger(0.1);

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <Box
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    height="fit-content"
                    paddingLeft={isMdDown ? 2 : 17}
                    zIndex={2}
                    sx={{ animation: slideDown+" 1s ease-out" }}
                >
                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6",
                            borderRight:"1px solid #B5CFD6"
                        }}
                    >
                        <Phone />
                    </Box>

                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6",
                            borderRight:"1px solid #B5CFD6"
                        }}
                    >
                        <Mail />
                    </Box>

                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6"
                        }}
                    >
                        <Location />
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="26vw"
                    maxWidth={400}
                    height={isMdDown ? 340 : 520}
                    bgcolor="#2086E5"
                    paddingTop={isMdDown ? 9 : 7}
                    sx={{ animation: growAnimation+" 1s ease-in-out" }}
                >
                    <Box
                        width="14vw"
                        maxWidth={170}
                        height={isMdDown ? 280 : 460}
                        bgcolor="#0F2025"
                        marginLeft={isMdDown ? -4 : -8}
                        sx={{ animation: slideInFromRight+" 1s ease-in-out" }}
                    />

                    <Box
                        width="14vw"
                        maxWidth={170}
                        height={isMdDown ? 280 : 460}
                        bgcolor="#0F2025"
                        marginRight={isMdDown ? -4 : -8}
                        sx={{ animation: slideInFromLeft+" 1s ease-in-out" }}
                    />
                </Box>

                <Box
                    position="absolute"
                    top={isMdDown ? "40%" : "43%"}
                    left="50%"
                    width="76%"
                    maxWidth={1000}
                    height="50vh"
                    maxHeight={isMdDown ? 120 : 300}
                    bgcolor="#D8E7EB"
                    border="1px solid #B5CFD6"
                    overflow="hidden"
                    sx={{ transform: "translateX(-50%)" }}
                >
                    <Typography
                        position="absolute"
                        fontFamily="Anton"
                        fontSize={isMdDown ? 150 : 400}
                        color="#B4CFD6"
                        marginTop={isMdDown ? -6 : -21}
                        marginLeft={isMdDown ? -3 : -8}
                        sx={{ animation: growAnimation+" 1s ease-in-out" }}
                    >
                        GLOBAL
                    </Typography>

                    <Typography
                        position="absolute"
                        top="50%"
                        left="40%"
                        fontFamily="Anton"
                        fontSize={isMdDown ? 80 : 240}
                        color="#102429"
                        sx={{
                            transform: "translate(-50%, -50%)",
                            animation: growAnimationForText+" 1.5s ease-in"
                        }}
                    >
                        DRONE
                    </Typography>
                    <Typography
                        position="absolute"
                        top="80%"
                        left="70%"
                        fontFamily="Anton"
                        fontSize={isMdDown ? 50 : 120}
                        color="orange"
                        sx={{
                            transform: "translate(-50%, -50%)",
                            animation: growAnimationForText+" 1.5s ease-in"
                        }}
                    >
                        WASH
                    </Typography>
                    

                </Box>

                <Box
                    component="img"
                    src={DronePNG}
                    alt="drone"
                    sx={{
                        position: "absolute",
                        top: "25%",
                        left: isMdDown ? "38%" : "47%",
                        width: isMdDown ? 300 : 650,
                        animation: growAnimation+" 1s ease-in-out"
                    }}
                />

                <Box
                    display="flex"
                    flexDirection="row"
                    height="fit-content"
                    paddingRight={isMdDown ? 2 : 17}
                    zIndex={2}
                    sx={{ animation: slideDown+" 1s ease-out" }}
                >
                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6",
                            borderRight:"1px solid #B5CFD6"
                        }}
                    >
                        <FacebookIcon sx={{ fontSize: '21px', cursor: 'pointer', ':hover': { opacity: 0.5,} }} ></FacebookIcon>
                    </Box>

                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6",
                            borderRight:"1px solid #B5CFD6"
                        }}
                    >
                        <Instagram />
                    </Box>

                    <Box
                        padding={2}
                        paddingLeft={isMdDown ? 2 : 3.5}
                        paddingRight={isMdDown ? 2 : 3.5}
                        sx={{
                            borderBottom: "1px solid #B5CFD6"
                        }}
                    >
                        <Linkedin />
                    </Box>
                </Box>
            </Box>

            <Grid2
                container
                ref={secondRef}
                marginLeft={isMdDown ? 3 : 9}
                marginRight={isMdDown ? 3 : 9}
                borderTop="1px solid #B5CFD6"
                sx={{
                    animation: secondVisible ? boxSlideUp+" 0.5s ease-out" : "none",
                    opacity: secondVisible ? 1 : 0,
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2
                    size={{ xs: 6, md: 3 }}
                    paddingTop={isMdDown ? 4 : 8}
                    paddingBottom={isMdDown ? 4 : 8}
                    borderRight="0.5px solid #B5CFD6"
                    borderBottom={isMdDown ? "0.5px solid #B5CFD6" : "none"}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        gap={2}
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 40 : 70}
                            color="#102429"
                        >
                            01
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography
                                textTransform="uppercase"
                                fontFamily="Rubik"
                                fontSize={isMdDown ? 10 : 14}
                                fontWeight={700}
                                color="#2086E5"
                                letterSpacing={2}
                            >
                                Mosás minősége
                            </Typography>

                            <Typography
                                textTransform="uppercase"
                                fontFamily="Anton"
                                fontSize={isMdDown ? 20 : 26}
                                color="#102429"
                            >
                                Garantált
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>

                <Grid2
                    size={{ xs: 6, md: 3 }}
                    paddingTop={isMdDown ? 4 : 8}
                    paddingBottom={isMdDown ? 4 : 8}
                    borderRight={isMdDown ? "none" : "0.5px solid #B5CFD6"}
                    borderBottom={isMdDown ? "0.5px solid #B5CFD6" : "none"}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        gap={2}
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 40 : 70}
                            color="#102429"
                        >
                            02
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography
                                textTransform="uppercase"
                                fontFamily="Rubik"
                                fontSize={isMdDown ? 6 : 12}
                                fontWeight={700}
                                color="#2086E5"
                                letterSpacing={1.5}
                            >
                                (leg)gyorsabb
                            </Typography>

                            <Typography
                                textTransform="uppercase"
                                fontFamily="Anton"
                                fontSize={isMdDown ? 20 : 26}
                                color="#102429"
                            >
                                Tisztítás
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>

                <Grid2
                    size={{ xs: 6, md: 3 }}
                    paddingTop={isMdDown ? 4 : 8}
                    paddingBottom={isMdDown ? 4 : 8}
                    borderRight="0.5px solid #B5CFD6"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        gap={2}
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 40 : 70}
                            color="#102429"
                        >
                            03
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography
                                textTransform="uppercase"
                                fontFamily="Rubik"
                                fontSize={isMdDown ? 10 : 14}
                                fontWeight={700}
                                color="#2086E5"
                                letterSpacing={2}
                            >
                                100%-ig biztonságos
                            </Typography>

                            <Typography
                                textTransform="uppercase"
                                fontFamily="Anton"
                                fontSize={isMdDown ? 20 : 26}
                                color="#102429"
                            >
                                Vegyszermentes
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>

                <Grid2
                    size={{ xs: 6, md: 3 }}
                    paddingTop={isMdDown ? 4 : 8}
                    paddingBottom={isMdDown ? 4 : 8}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        gap={2}
                    >
                        <Typography
                            fontFamily="Anton"
                            fontSize={isMdDown ? 40 : 70}
                            color="#102429"
                        >
                            04
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography
                                textTransform="uppercase"
                                fontFamily="Rubik"
                                fontSize={isMdDown ? 10 : 14}
                                fontWeight={700}
                                color="#2086E5"
                                letterSpacing={2}
                            >
                                extrán
                            </Typography>

                            <Typography
                                textTransform="uppercase"
                                fontFamily="Anton"
                                fontSize={isMdDown ? 20 : 26}
                                color="#102429"
                            >
                                költséghatékony
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>

            <Grid2
                container
                bgcolor="#0F2025"
            >
                <Grid2
                    container
                    ref={firstRef}
                    size={{ xs: 12 }}
                    sx={{
                        animation: firstVisible ? boxSlideDown+" 0.5s ease-out" : "none",
                        opacity: firstVisible ? 1 : 0,
                        maxWidth: "1200px",
                        margin: "0 auto",
                        width: "100%"
                    }}
                >
                    <Grid2
                        container
                        size={{ xs: 12 }}
                        marginLeft={isMdDown ? 3 : 9}
                        marginRight={isMdDown ? 3 : 9}
                    >
                        <Grid2
                            size={{ xs: 6, md: 3 }}
                            paddingTop={3}
                            paddingBottom={3}
                            borderRight="0.5px solid #1D363C"
                            borderBottom="0.5px solid #1D363C"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignItems="center"
                            >
                                <Typography
                                    fontFamily="Anton"
                                    fontSize={isMdDown ? 40 : 70}
                                    color="#FFF"
                                    textTransform="uppercase"
                                >
                                    1000 +
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Rubik"
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#2086E5"
                                    letterSpacing={2}
                                >
                                    Ablakok
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Anton"
                                    fontSize={26}
                                    color="#FFF"
                                >
                                    részletesen
                                </Typography>
                            </Box>
                        </Grid2>

                        <Grid2
                            size={{ xs: 6, md: 3 }}
                            paddingTop={3}
                            paddingBottom={3}
                            borderBottom="0.5px solid #1D363C"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignItems="center"
                            >
                                <Typography
                                    fontFamily="Anton"
                                    fontSize={isMdDown ? 40 : 70}
                                    color="#FFF"
                                    textTransform="uppercase"
                                >
                                    3 ezer
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Rubik"
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#2086E5"
                                    letterSpacing={2}
                                >
                                    Vállalati
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Anton"
                                    fontSize={26}
                                    color="#FFF"
                                >
                                    Épületek
                                </Typography>
                            </Box>
                        </Grid2>

                        <Grid2
                            size={{ xs: 6, md: 3 }}
                            paddingTop={3}
                            paddingBottom={3}
                            borderRight="0.5px solid #1D363C"
                            borderBottom="0.5px solid #1D363C"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignItems="center"
                            >
                                <Typography
                                    fontFamily="Anton"
                                    fontSize={isMdDown ? 40 : 70}
                                    color="#FFF"
                                    textTransform="uppercase"
                                >
                                    5 ezer
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Rubik"
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#2086E5"
                                    letterSpacing={2}
                                >
                                    Boldog
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Anton"
                                    fontSize={26}
                                    color="#FFF"
                                >
                                    Ügyfelek
                                </Typography>
                            </Box>
                        </Grid2>

                        <Grid2
                            size={{ xs: 6, md: 3 }}
                            paddingTop={3}
                            paddingBottom={3}
                            borderBottom="0.5px solid #1D363C"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignItems="center"
                            >
                                <Typography
                                    fontFamily="Anton"
                                    fontSize={isMdDown ? 40 : 70}
                                    color="#FFF"
                                    textTransform="uppercase"
                                >
                                    2 ezer
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Rubik"
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#2086E5"
                                    letterSpacing={2}
                                >
                                    Lakói
                                </Typography>

                                <Typography
                                    textTransform="uppercase"
                                    fontFamily="Anton"
                                    fontSize={26}
                                    color="#FFF"
                                >
                                    Otthonok
                                </Typography>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default Home;