import useScrollTrigger from "../hooks/useScrollTrigger";

import PhoneImg from "../assets/footer/phone.svg";
import MailImg from "../assets/footer/mail.svg";
import LocationImg from "../assets/footer/location.svg";
import LogoImg from "../assets/logo_black.svg";

import Instagram from "../assets/footer/Instagram";
import FacebookIcon from '@mui/icons-material/Facebook';
import Linkedin from "../assets/footer/Linkedin";

import { Box, Button, Grid2, keyframes, useMediaQuery, useTheme } from "@mui/material";

import FooterButton from "../components/FooterButton";

const slideDown = keyframes`
    from {
        transform: translateY(-30%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Footer = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [firstVisible, firstRef] = useScrollTrigger(0.1);

    return (
        <Grid2
            container 
            bgcolor="#102429"
        >
            <Grid2
                container
                size={{ xs: 12 }}
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: "100%"
                }}
            >
                <Grid2
                    container
                    size={{ xs: 12 }}
                    paddingLeft={isMdDown ? 3 : 10}
                    paddingRight={isMdDown ? 3 : 10}
                    ref={firstRef}
                    sx={{
                        animation: firstVisible ? slideDown+" 0.6s ease-out" : "none",
                        opacity: firstVisible ? 1 : 0,
                        zIndex: 2
                    }}
                >
                    <Grid2
                        size={{ xs: 12, md: 9 }}
                        height={isMdDown ? 50 : 155}
                        borderBottom={isMdDown ? "none" : "1px solid #1D373D"}
                    >
                        <Grid2 container size={{ xs: 12 }} height="100%">
                            <Grid2 size={{ xs: 12, md: 6 }} height="100%">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent={isMdDown ? "center" : "flex-start"}
                                    gap={2}
                                    width="100%"
                                    height="100%"
                                >
                                    <FooterButton
                                        icon={PhoneImg}
                                        text="(647) 709 5026"
                                    />

                                    <FooterButton
                                        icon={MailImg}
                                        text="info@droneskycleaners.com"
                                    />
                                </Box>
                            </Grid2>

                            <Grid2 size={{ xs: 12, md: 6 }} height="100%">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent={isMdDown ? "center" : "flex-start"}
                                    gap={2}
                                    width="100%"
                                    height="100%"
                                >
                                    <FooterButton
                                        icon={LocationImg}
                                        text="1001 Finch Ave W. M3J 2C7"
                                    />


                                </Box>
                            </Grid2>
                        </Grid2>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, md: 3 }}
                        height={155}
                        paddingTop={isMdDown ? 5 : 0}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent={isMdDown ? "center" : "flex-start"}
                            width="100%"
                            height="100%"
                        >
                            <Box
                                display="flex"
                                height="fit-content"
                                padding={3}
                                borderLeft={isMdDown ? "none" : "1px solid #1D373D"}
                                borderRight="1px solid #1D373D"
                                marginLeft={isMdDown ? 0 : "auto"}
                            >
                                <FacebookIcon sx={{ color: "white", cursor: 'pointer', ':hover': { opacity: 0.5,} }}></FacebookIcon>
                            </Box>

                            <Box
                                display="flex"
                                height="fit-content"
                                padding={3}
                                borderRight="1px solid #1D373D"
                            >
                                <Instagram />
                            </Box>

                            <Box
                                display="flex"
                                height="fit-content"
                                padding={3}
                            >
                                <Linkedin />
                            </Box>
                        </Box>
                    </Grid2>

                    <Grid2 container size={{ xs: 12 }}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                padding={2}
                                gap={2}
                            >
                                <img
                                    src={LogoImg}
                                    alt="logo"
                                    style={{ width: "150px", height: "122px" }}
                                />

                                {!isMdDown &&
                                    <>
                                        <a href="#about">
                                            <Button
                                                size="small"
                                                sx={{
                                                    fontFamily: "Rubik",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    color: "#FFF",
                                                    opacity: 1,
                                                    letterSpacing: 2,
                                                    "&:hover": {
                                                        opacity: 0.5,
                                                        bgcolor: "inherit"
                                                    }
                                                }}
                                            >
                                                RÓLUNK
                                            </Button>
                                        </a>

                                        <a href="#services">
                                            <Button
                                                size="small"
                                                sx={{
                                                    fontFamily: "Rubik",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    color: "#FFF",
                                                    opacity: 1,
                                                    letterSpacing: 2,
                                                    "&:hover": {
                                                        opacity: 0.5,
                                                        bgcolor: "inherit"
                                                    }
                                                }}
                                            >
                                                SZOLGÁLTATÁSOK
                                            </Button>
                                        </a>

                                        <a href="#contact">
                                            <Button
                                                size="small"
                                                sx={{
                                                    fontFamily: "Rubik",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    color: "#FFF",
                                                    opacity: 1,
                                                    letterSpacing: 2,
                                                    "&:hover": {
                                                        opacity: 0.5,
                                                        bgcolor: "inherit"
                                                    }
                                                }}
                                            >
                                                AJÁNLATKÉRÉS
                                            </Button>
                                        </a>
                                    </>
                                }
                            </Box>

                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
};

export default Footer;