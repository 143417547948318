import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, useMediaQuery, useTheme, Slide } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Comment from "../components/Comment";
import { Element } from 'react-scroll';

const Feedbacks = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState("left");

    const feedbackData = [
        { name: "Michael Bronson", content: "Ez őszintén szólva egy KÖTELEZŐ szolgáltatás. Nemcsak hogy az ablakok tisztításának költsége jelentősen alacsonyabb a hagyományos módszereknél, de a használt vegyszerek is biztonságosabbak, és a munka sokkal gyorsabban elkészül. Egyértelmű választás", starfilled: "5", staroutline: "0" },
        { name: "Jennifer O'Neal", content: "WASH GLOBAL szolgáltatásait vettük igénybe a Dubajban található raktárunkhoz, amelynek egész évben rendszeres ablakmosásra volt szüksége. Ezek a srácok messze a legjobb ablakmosó cég a GTA-ban, és a munkát is sokkal gyorsabban végzik el.", starfilled: "4", staroutline: "1" },
        { name: "Alex Johnson", content: "Vélemény 3", starfilled: "3", staroutline: "2" },
        { name: "Lucy Smith", content: "Vélemény 4", starfilled: "4", staroutline: "1" },
        { name: "Valaki János", content: "Vélemény 5", starfilled: "4", staroutline: "1" },
        { name: "Bárki Akárki", content: "Vélemény 6", starfilled: "5", staroutline: "0" },

    ];

    const handleNext = () => {
        setSlideDirection("left");
        const step = isMdDown ? 1 : 2;
        setCurrentIndex((prevIndex) => (prevIndex + step) % feedbackData.length);
    };

    const handlePrev = () => {
        setSlideDirection("right");
        const step = isMdDown ? 1 : 2;
        setCurrentIndex((prevIndex) =>
            prevIndex - step < 0 ? feedbackData.length - step : prevIndex - step
        );
    };

    const displayedFeedbacks = feedbackData.slice(currentIndex, currentIndex + (isMdDown ? 1 : 2));

    return (
        <Element
            id="comments"
            style={{
                scrollMarginTop: 145,
                paddingTop: 24,
                paddingBottom: 16
            }}
        >
            <Box sx={{ maxWidth: "1200px", margin: "0 auto", width: "100%", padding: "0 16px", position: "relative" }}>
                {/* Visszajelzések megjelenítése slide animációval */}
                <Slide in={true} direction={slideDirection} timeout={500} key={currentIndex} style={{ pointerEvents: "none" }}>
                    <Grid container spacing={2} alignItems="center">
                        {displayedFeedbacks.map((feedback, idx) => (
                            <Grid item xs={12} md={6} key={idx}>
                                <Comment
                                    name={feedback.name}
                                    content={feedback.content}
                                    starfilled={feedback.starfilled}
                                    staroutline={feedback.staroutline}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Slide>

                {/* Lapozó nyilak középen */}
                <IconButton 
                    onClick={handlePrev} 
                    aria-label="Previous Feedback" 
                    sx={{ 
                        position: "absolute", 
                        left: 30, 
                        top: "50%", 
                        transform: "translateY(-50%)",
                        zIndex: 2 // Biztosítjuk, hogy a nyíl látható és elérhető legyen
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>

                <IconButton 
                    onClick={handleNext} 
                    aria-label="Next Feedback" 
                    sx={{ 
                        position: "absolute", 
                        right: 30, 
                        top: "50%", 
                        transform: "translateY(-50%)",
                        zIndex: 2 // Biztosítjuk, hogy a nyíl látható és elérhető legyen
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>

                {/* Pöttyök a visszajelzések navigációjához */}
                <Box display="flex" justifyContent="center" mt={4} padding={2}>
                    {[...Array(Math.ceil(feedbackData.length / (isMdDown ? 1 : 2)))].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 10,
                                height: 10,
                                bgcolor: Math.floor(currentIndex / (isMdDown ? 1 : 2)) === index ? "#2086E5" : "#B5CFD6",
                                borderRadius: "50%",
                                marginX: 0.5,
                                transition: "background-color 0.3s ease"
                            }}
                        />
                    ))}
                </Box>
            </Box>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
                paddingTop={2}
                paddingBottom={8}
            >
                <Divider
                    sx={{
                        width: "70px",
                        bgcolor: "#2086E5"
                    }}
                />
            </Box>
        </Element>    
    );
};

export default Feedbacks;