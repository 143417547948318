import { useState } from "react";

const Location = () => {
    const [hovered, setHovered] = useState(false);

    return (
        <svg
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill={hovered ? "#6A7A7D" : "#000"}
            style={{ transition: "fill 0.2s ease", cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.75811 15.6405C8.3654 13.629 12.0311 8.7539 12.0311 6.01557C12.0311 2.69447 9.33666 0 6.01557 0C2.69447 0 0 2.69447 0 6.01557C0 8.7539 3.66574 13.629 5.27302 15.6405C5.65839 16.1198 6.37274 16.1198 6.75811 15.6405ZM6.01557 8.02076C4.90958 8.02076 4.01038 7.12155 4.01038 6.01557C4.01038 4.90958 4.90958 4.01038 6.01557 4.01038C7.12155 4.01038 8.02076 4.90958 8.02076 6.01557C8.02076 7.12155 7.12155 8.02076 6.01557 8.02076Z" />
        </svg>

    );
};

export default Location;