import { useEffect, useState } from 'react';

import { Button, ThemeProvider, createMuiTheme, keyframes } from '@mui/material';

import ArrowIcon from "./assets/arrow.svg";

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Feedbacks from './pages/Feedbacks';
import Reference from './pages/Reference';
import Steps from './pages/Steps';
import Contact from './pages/Contact';
import AfterBefore from './components/AfterBefore';

import NavBar from './layouts/NavBar';
import Footer from './layouts/Footer';

const THEME = createMuiTheme({
    typography: {
        "fontFamily": `"Poppins", sans-serif`,
        "fontSize": 14
    }
 });

 const btnAnim = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const App = () => {
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if(window.scrollY > 150) {
            setScrolling(true);
        }
        else {
            setScrolling(false);
        }
    };

    return (
        <ThemeProvider theme={THEME}>
            <Button
                href="#contact"
                variant="contained"
                size="large"
                sx={{
                    position: "fixed",
                    bottom: 20,
                    left: 20,
                    zIndex: 1000,
                    fontFamily: "Rubik",
                    fontSize: 17,
                    fontWeight: 700,
                    textTransform: "none",
                    borderRadius: 0,
                    bgcolor: "#1386EB",
                    height: 45,
                    "&:hover": {
                        bgcolor: "#299BFF"
                    }
                }}
            >
                Ajánlatkérés
            </Button>

            {scrolling &&
                <Button
                    href="#"
                    variant="contained"
                    size="large"
                    sx={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        zIndex: 1000,
                        fontFamily: "Rubik",
                        fontSize: 17,
                        fontWeight: 700,
                        textTransform: "none",
                        borderRadius: 0,
                        bgcolor: "#1386EB",
                        height: 45,
                        animation: btnAnim+" 0.2s ease-in",
                        "&:hover": {
                            bgcolor: "#299BFF"
                        }
                    }}
                >
                    <img
                        src={ArrowIcon}
                        alt="arrow"
                        style={{ width: 24, height: 24 }}
                    />
                </Button>
            }

            <NavBar scrolling={scrolling} />

            <Home />
            <About />
            <Contact />
            <Services />
            <Feedbacks />
            <AfterBefore></AfterBefore>
            <Reference />
            <Steps />
            <Footer />
        </ThemeProvider>
    );
};

export default App;