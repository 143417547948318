const ContactInput = ({ type, ph, val, change }) => {
    return (
        <input
            type={type}
            value={val}
            onChange={change}
            placeholder={ph}
            required
            style={{
                fontFamily: "Rubik",
                fontSize: "15px",
                padding: 20,
                borderRadius: 0,
                boxShadow: "none",
                border: "1px solid #FFF",
                outline: "none",
                boxSizing: "border-box"
            }}
            onFocus={(e) => {
                e.target.style.outline = "none"
                e.target.style.border = "1px solid #1386EB"
            }}
            onBlur={(e) => e.target.style.border = "1px solid #FFF"}
        />
    );
};

export default ContactInput;